.dialogTitle{
    height: 40px;
    width: 100%;
    background-color: #ff6450;
    padding: 5px;
}

.dialogTitle h6{
    padding-left: 20px;
    color: #ffffff
}