@font-face {
    font-family: 'Audiowide-Regular';
    src: url('./fonts/Audiowide-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'IstokWeb-Regular';
    src: url('./fonts/IstokWeb-Regular.ttf') format('truetype');
}

