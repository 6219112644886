.GrayTextField{
    border-radius: 5px;
    background-color: #f5f5f5;
}

.SelectedTextField{
    border-radius: 5px;
    background-color: rgba(98, 144, 200, 0.2);
}

.dialogTitle{
    height: 40px;
    width: 100%;
    background-color: #ff6450;
    padding: 2px;
}

.dialogTitle h6{
    color: #ffffff
}