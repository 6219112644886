body {
    background-color: #f5f5f5;
}

.bg {
    background-image: url('./bg_and_man.png');
    background-repeat: no-repeat;
    height: 90vh;
    transform:scale(0.8)
}

.appTitle {
    position: absolute;
    left: 500px;
    top: 10px;
    z-index: 2;
    font-family: 'Audiowide-Regular', sans-serif;
    font-size: 72px;
    background: linear-gradient(180deg, #ff6450 0, #ff3a2c);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.flavorText {
    position: absolute;
    left: 505px;
    top: 170px;
    z-index: 2;
}

.login {
    background: rgba(255, 255, 255);
    position: fixed;
    border-radius: 80px 0px 0px 80px;
    height: 350px;
    width: 400px;
    top: 45%;
    right: 0%;
    z-index: 2;
    transform: translate(0%, -50%);
}

.login div{
    margin-top: 15px;
    width: 100%;
}

.login form{
    margin-top: 30px;
    margin-left: 60px;
    margin-right: 60px;
}

.login p {
    margin-top: 15px;
}

.login input{
    width: 100px;
}

.login Button{
    margin-top: 10px;
    width: 100%;
    background: linear-gradient(180deg, #ff6450 0, #ff3a2c);
}
