.customMarker {
    position:absolute;
    cursor:pointer;
    width:50px;
    height:50px;
    /* -width/2 */
    margin-left:-50px;
    /* -height + arrow */
    margin-top:-80px;
    border-radius: 50%;
    padding-left: 24px;
    padding-top:23px;
}

.customMarker:after {
    content:"";
    position: absolute;
    bottom: -31px;
    left: 40px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #ffffff transparent;
    display: block;
    display: inline;
    width: 0;
    z-index: -1;
}

.customMarker img {
    background-color: #ffffff;
    width:52px;
    height:50px;
    border-radius: 50%;
    z-index: 2;
}